/* Copyright (©) 2021. Care Control Systems Limited. All rights reserved. */


// METHODS

    // Has value.
    export const hasValue = function (value) {
        return value ? true : 'A value must be provided for this field!';
    };

//


// EXPORT

    export default {
        hasValue
    };

//