<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <Form class="form">
        <Field
            placeholder="Enter your location..."
            v-model="location"
            :validation="[hasValue]"
            ref="location"
            class="location-input"
        />
        <Button
            :action="search"
            title="Search for jobs!"
            class="search-button"
        >
            <Image
                filePath="search.svg"
                class="search-icon"
                alternativeText="Search"
            />
        </Button>
    </Form>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Form from '@/components/generic/Form';
        import Field from '@/components/generic/Field';
        import Button from '@/components/generic/Button';
        import Image from '@/components/generic/Image';

        // Common.
        import { hasValue } from '@/common/Validation';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Form,
            Field,
            Button,
            Image
        };

    //

    // DATA

        // Define data.
        const data = function () {
            return {
                location: ''
            };
        };

    //

    // METHODS

        // Search.
        const search = function () {

            // Invoke child components validation method.
            const isValid = this.$refs.location.validate();

            // If component is valid.
            if (isValid) {

                // Go to 'jobs-list' view, set 'location' query parameter.
                this.$router.push({
                    name: 'job-search',
                    query: {
                        location: this.location
                    }
                });

            }

        };

        // Define methods.
        const methods = {
            search,
            hasValue
        };

    //

    // EXPORT

        export default {
            name: 'LocationSearch',
            components,
            data,
            methods
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // CLASSES

        .form {
            background-color: $colour-light-trans;
            display: flex;
            gap: .5rem;
            padding: .5rem;
            border-radius: 10rem;
            border: $layout-border;
        }

        .search-button {
            height: 4rem;
            width: 4rem;
            border-radius: 4rem;
        }

        .search-icon {
            height: 2rem;
        }

    //

</style>
