<!-- Copyright (©) 2021. Care Control Systems Limited. All rights reserved. -->


<template>
    <Main class="center">
        <div class="container">
            <Image
                filePath="logo-colour.svg"
                alternativeText="FindMeACareJob logo"
                class="logo"
            />
            <h1>
                <span class="start">FindMeA</span><span class="end">CareJob</span>
            </h1>
            <LocationSearch />
        </div>
    </Main>
</template>


<script>

    // DEPENDENCIES

        // Components.
        import Main from '@/components/generic/Main';
        import Image from '@/components/generic/Image';
        import LocationSearch from '@/components/modules/LocationSearch';

    //

    // COMPONENTS

        // Define components.
        const components = {
            Main,
            Image,
            LocationSearch
        };

    //

    // EXPORT

        export default {
            name: 'Landing',
            components
        };

    //

</script>


<style lang="scss" scoped>

    // DEPENDENCIES

        // Theme.
        @import '/src/styles/theme.scss';

    //

    // ELEMENTS

        h1 {
            @extend .title;
            font-weight: 600;
            margin: 0;
            padding: .5rem;
            .start {
                color: $colour-secondary;
            }
            .end {
                color: $colour-primary;
            }
            @media screen and (min-width: $extra-small) {
                font-size: 2.4rem;
            }
            @media screen and (min-width: $medium) {
                font-size: 4rem;
            }
        }

    //

    // CLASSES

        .center {
            align-content: center;
        }

        .container {
            display: grid;
            gap: 1rem;
            align-self: center;
            justify-self: center;
            justify-items: center;
            text-align: center;
        }

        .logo {
            max-width: 50%;
            @media screen and (min-width: $medium) {
                max-width: 300px;
            }
        }

    //

</style>
